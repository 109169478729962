import { Entry } from "contentful";
import Link from "next/link";
import { useRouter } from "next/router";

import MediaAsset from "~/components/common/media-asset";
import Editorials from "~/types/editorials";
import contentfulUtils from "~/utils/contentful-utils";
import { assertEditorialType, isActiveEntry } from "~/utils/editorial-utils";
import textUtils from "~/utils/text-utils";

import { CarouselWrapper } from "../carousel-utils/carousel-utils";
import styles from "./links-carousel.module.scss";

type Props = {
  entry: Entry<unknown>;
};

export default function LinksCarousel({ entry }: Props) {
  if (!isActiveEntry(entry)) return null;

  assertEditorialType<Editorials.LinksCarousel>(entry, "linksCarousel");

  return (
    <CarouselWrapper entry={entry}>
      <div className={styles.linksContainer}>
        {entry.fields.links?.filter(isActiveEntry).map((link, i) => (
          <LinkCard key={`${link.sys.id}-${i}`} link={link} />
        ))}
      </div>
    </CarouselWrapper>
  );
}

export function LinkCard({ link }: { link: Entry<Editorials.Link> }) {
  const router = useRouter();
  const inspectorModeLink = contentfulUtils.useInspectorMode(link);

  return (
    <Link
      href={textUtils.sanitizeContentfulUrl(link, router)}
      target={link.fields.openOnANewTab ? "_blank" : undefined}
      className={styles.linkBox}
    >
      {link.fields.image && (
        <div className={styles.imgWrapper} {...inspectorModeLink?.getProps("image")}>
          <MediaAsset className={styles.imgForeground} entry={link.fields.image} />
          {link.fields.backgroundImage && (
            <MediaAsset className={styles.imgBackground} entry={link.fields.backgroundImage} />
          )}
        </div>
      )}
      <p {...inspectorModeLink?.getProps("text")}>{link.fields.text}</p>
    </Link>
  );
}
